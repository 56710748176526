<template>
  <div>
    <menu-item v-for="(item, index) in items" :key="index" v-bind="item" :style="menuItemStyle" :data-icon="item.icon" />
  </div>
</template>

<script>
// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, watch } from 'vue'
import { useStore } from 'vuex'

import { themeColors } from '@/common/shared.js'

import MenuItem from './MenuItem.vue'

export default {
  components: {
    MenuItem,
  },

  props: {
    editor: {
      type: Object,
      required: false,
    },
  },
  setup(props) {
    const store = useStore()

    // Determine preferred Super Key
    const getUA = window.navigator.userAgent
    const isMac = getUA.includes('Mac OS')
    const superKey = isMac ? '⌘' : 'Ctrl'

    const itemTitles = {
      'bold': `Bold (${superKey}+B)`,
      'italic': `Italic (${superKey}+I)`,
      'strikethrough': `Strike (${superKey}+Shift+X)`,
      'h-1': `Heading 1 (${superKey}+Alt+1)`,
      'h-2': `Heading 2 (${superKey}+Alt+2)`,
      'paragraph': `Paragraph (${superKey}+Alt+0)`,
      'align-left': `Left Align (${superKey}+Shift+L)`,
      'align-center': `Center Align (${superKey}+Shift+E)`,
      'align-right': `Right Align (${superKey}+Shift+R)`,
      'align-justify': `Justify (${superKey}+Shift+J)`,
      'list-unordered': `Bullet List (${superKey}+Shift+8)`,
      'list-ordered': `Ordered List (${superKey}+Shift+7)`,
      'separator': 'Horizontal Rule',
      'text-wrap': 'Hard Break (Shift+Enter)',
      'format-clear': 'Clear Format',
      'arrow-go-back-line': `Undo (${superKey}+Z)`,
      'arrow-go-forward-line': `Redo (${superKey}+Y)`,
    }

    const language = computed(() => store.getters.profile.language)

    watch(language, () => {
      if (!editor.value) { return }
      const menuBarChildren = editor.value.view.dom.parentElement.previousSibling.children
      for (let menuBarChild of menuBarChildren) {
        const icon = menuBarChild.dataset.icon
        menuBarChild.title = store.getters.translate(itemTitles[icon])
      }
    })

    const items = [{
      icon: 'bold',
      title: store.getters.translate(itemTitles['bold']),
      action: () => editor.value.chain().focus().toggleBold().run(),
      isActive: () => editor.value.isActive('bold'),
    },
    {
      icon: 'italic',
      title: store.getters.translate(itemTitles['italic']),
      action: () => editor.value.chain().focus().toggleItalic().run(),
      isActive: () => editor.value.isActive('italic'),
    },
    {
      icon: 'strikethrough',
      title: store.getters.translate(itemTitles['strikethrough']),
      action: () => editor.value.chain().focus().toggleStrike().run(),
      isActive: () => editor.value.isActive('strike'),
    },
    {
      icon: 'h-1',
      title: store.getters.translate(itemTitles['h-1']),
      action: () => editor.value.chain().focus().toggleHeading({ level: 1 }).run(),
      isActive: () => editor.value.isActive('heading', { level: 1 }),
    },
    {
      icon: 'h-2',
      title: store.getters.translate(itemTitles['h-2']),
      action: () => editor.value.chain().focus().toggleHeading({ level: 2 }).run(),
      isActive: () => editor.value.isActive('heading', { level: 2 }),
    },
    {
      icon: 'paragraph',
      title: store.getters.translate(itemTitles['paragraph']),
      action: () => editor.value.chain().focus().setParagraph().run(),
      isActive: () => editor.value.isActive('paragraph'),
    },
    {
      icon: 'align-left',
      title: store.getters.translate(itemTitles['align-left']),
      action: () => editor.value.chain().focus().setTextAlign('left').run(),
      isActive: () => editor.value.isActive({ textAlign: 'left' }),
    },
    {
      icon: 'align-center',
      title: store.getters.translate(itemTitles['align-center']),
      action: () => editor.value.chain().focus().setTextAlign('center').run(),
      isActive: () => editor.value.isActive({ textAlign: 'center' }),
    },
    {
      icon: 'align-right',
      title: store.getters.translate(itemTitles['align-right']),
      action: () => editor.value.chain().focus().setTextAlign('right').run(),
      isActive: () => editor.value.isActive({ textAlign: 'right' }),
    },
    {
      icon: 'align-justify',
      title: store.getters.translate(itemTitles['align-justify']),
      action: () => editor.value.chain().focus().setTextAlign('justify').run(),
      isActive: () => editor.value.isActive({ textAlign: 'justify' }),
    },
    {
      icon: 'list-unordered',
      title: store.getters.translate(itemTitles['list-unordered']),
      action: () => editor.value.chain().focus().toggleBulletList().run(),
      isActive: () => editor.value.isActive('bulletList'),
    },
    {
      icon: 'list-ordered',
      title: store.getters.translate(itemTitles['list-ordered']),
      action: () => editor.value.chain().focus().toggleOrderedList().run(),
      isActive: () => editor.value.isActive('orderedList'),
    },
    {
      icon: 'separator',
      title: store.getters.translate(itemTitles['separator']),
      action: () => editor.value.chain().focus().setHorizontalRule().run(),
    },
    {
      icon: 'text-wrap',
      title: store.getters.translate(itemTitles['text-wrap']),
      action: () => editor.value.chain().focus().setHardBreak().run(),
    },
    {
      icon: 'format-clear',
      title: store.getters.translate(itemTitles['format-clear']),
      action: () => editor.value.chain().focus().clearNodes().unsetAllMarks().run(),
    },
    {
      icon: 'arrow-go-back-line',
      title: store.getters.translate(itemTitles['arrow-go-back-line']),
      action: () => editor.value.chain().focus().undo().run(),
    },
    {
      icon: 'arrow-go-forward-line',
      title: store.getters.translate(itemTitles['arrow-go-forward-line']),
      action: () => editor.value.chain().focus().redo().run(),
    }]

    const menuItemStyle = computed(() => {
      return {
        '--color': themeColors[store.getters.currentThemeName].modalColor,
        '--color-hover': themeColors[store.getters.currentThemeName].modalIconColor,
        '--background-color-hover': themeColors[store.getters.currentThemeName].accentColor,
      }
    })

    const editor = computed(() => props.editor)
    watch(editor, (newValue) => {
      console.log('watch editor:', newValue)
    })

    return {
      items,
      menuItemStyle,
    }
  },
}
</script>

<style lang="scss" scoped>
.menu-item {
  color: var(--color);
}

.menu-item.is-active,
.menu-item:hover {
  color: var(--color-hover);
  background-color: var(--background-color-hover);
}
</style>
